<template>
    <section data-bs-version="5.1" class="contacts1 cid-tKfYtWKAXV mbr-parallax-background" id="contact">
        <div class="mbr-overlay" style="opacity: 0.9; background-color: rgb(255, 255, 255);">
        </div>
        <div class="container">
            <div class="mbr-section-head">
                <h4 class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-7">
                    Kontaktieren Sie uns, um mehr über unser Team und unsere Dienstleistungen zu erfahren.
                    <br>
                    Wir freuen uns darauf, Ihnen mit unserem Fachwissen und unserer unternehmerischen Erfahrung dabei zu helfen, Ihren Geschäftserfolg zu steigern und Ihre Ziele zu erreichen.
                </h4>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="card col-12 col-lg-6">
                    <div class="card-wrapper">
                        <div class="card-box align-center">
                            <div class="image-wrapper">
                                <span class="mbr-iconfont mbri-letter"></span>
                            </div>
                            <h4 class="card-title mbr-fonts-style mb-2 display-2">
                                <strong>E-Mail</strong>
                            </h4>
                            <p class="mbr-text mbr-fonts-style mb-2 display-4">Kontaktieren Sie uns per E-Mail um mehr zu
                                erfahren</p>
                            <h5 class="link mbr-fonts-style display-7">
                                <a href="mailto:office@haicu.at" class="text-primary">
                                    E-Mail versenden
                                </a>
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="card col-12 col-lg-6">
                    <div class="card-wrapper">
                        <div class="card-box align-center">
                            <div class="image-wrapper">
                                <span class="mbr-iconfont mbri-smile-face"></span>
                            </div>
                            <h4 class="card-title mbr-fonts-style align-center mb-2 display-2">
                                <strong>Chatbot</strong>
                            </h4>
                            <p class="mbr-text mbr-fonts-style mb-2 display-4">Unser Chatbot erzählt Ihnen gerne mehr über
                                uns</p>
                            <h5 class="link mbr-black mbr-fonts-style display-7">
                                <a href="index.html#chatbotbox" class="text-primary">
                                    Zu unserem Chatbot
                                </a>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
A.text-primary {
    color: color(srgb 0.1309 0.6011 0.6681) !important;
}
A.text-primary:hover,
A.text-primary:focus,
A.text-primary.active {
  color: color(srgb 0.1309 0.6011 0.6681) !important;
}
</style>