// components/CookieBanner.vue
<template>
    <div class="cookie-banner"
        v-if="!didSpecifyCookieOption">
      <p>
        Willkommen!<br>
        Wir verwenden Cookies, um Ihr Erlebnis zu verbessern. Dabei ist uns Ihre Privatsphäre sehr wichtig!<br>
        Klicken Sie auf "Cookies akzeptieren", um unsere Webseite optimal zu nutzen und uns zu erlauben, Cookies zu verwenden und anonymisierte Daten zu erheben.<br>
        Wir respektieren Ihre Daten und nutzen sie verantwortungsvoll.
      </p>
      <button type="button" @click="acceptCookies">🍪 Cookies akzeptieren</button>
      <button type="button" @click="declineCookies">👎 Cookies ablehnen</button>
    </div>
  </template>

<script>
export default {
  name: "CookieBanner",
};
</script>

<style scoped>
.cookie-banner {
    background-color: #daebe0;
    
    padding: 20px;
    text-align: center;

    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    z-index: 99999;

    border-radius: 12px;
    border: 4px solid white;
}

.cookie-banner P {
    line-height: 1.5;
}

.cookie-banner BUTTON {
    color: #2299aa;
    font-weight: 500;
}
</style>

<script setup>
import { ref } from 'vue'
import { getCurrentInstance } from 'vue'

let posthogPlugin = getCurrentInstance().appContext.config.globalProperties.$posthog

let didSpecifyCookieOption = ref(posthogPlugin.has_opted_in_capturing() || posthogPlugin.has_opted_out_capturing())

function acceptCookies() {
    posthogPlugin.opt_in_capturing()
    didSpecifyCookieOption.value = true
}
function declineCookies() {
    posthogPlugin.opt_out_capturing()
    didSpecifyCookieOption.value = true
}
</script>