<template>
    <section data-bs-version="5.1" class="content1 cid-s48udlf8KU" id="about">
        <div class="container">
            <div class="row justify-content-center">
                <div class="title col-12 col-md-9">
                    <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2"><strong>Wer wir sind</strong>
                    </h3>
                    <h4 class="mbr-section-subtitle align-center mbr-fonts-style mb-4 display-7">Wir sind ein Team von
                        engagierten
                        Oberösterreichern, die nicht nur Software Engineers, sondern auch erfahrene Unternehmer sind. Durch
                        unsere
                        bisherige unternehmerische Tätigkeit haben wir wertvolles Wissen und mehr als 13 Jahre Erfahrung in
                        den
                        Bereichen Engineering, Projektmanagement und IT-Consulting gesammelt.<br><br>Unsere Ausbildung als
                        Absolventen
                        der Johannes Kepler Universität Linz und der Fachhochschule Hagenberg bildet das solide Fundament
                        unserer
                        technischen Kompetenzen. Als Softwareentwickler haben wir uns auf die Bereiche App-Development,
                        Web-Development, Software-Engineering und Machine Learning spezialisiert.<div></div>
                        <div>Unser breites Wissen und unsere langjährige Erfahrung ermöglichen es uns, nicht nur technische
                            Lösungen
                            zu entwickeln, sondern auch Geschäftsprozesse zu optimieren und strategische Beratung
                            anzubieten. Mit
                            unserer Expertise in Projektmanagement und IT-Consulting unterstützen wir unsere Kunden dabei,
                            ihre Ziele
                            effizient zu erreichen und den größtmöglichen Nutzen aus ihren Softwareprojekten zu ziehen.
                        </div>
                        <div></div>
                        <div>Wir setzen modernste Technologien ein, um innovative Lösungen zu entwickeln, die auf dem
                            neuesten Stand
                            der Technik sind. Unsere Kunden profitieren von unserer Fähigkeit, technische Herausforderungen
                            zu meistern
                            und maßgeschneiderte Softwarelösungen zu liefern, die ihren individuellen Anforderungen gerecht
                            werden.
                        </div>
                    </h4>
                </div>
            </div>
        </div>
    </section>
</template>