<template>
    <section data-bs-version="5.1" class="features4 cid-tKcoiQvxd4" id="usp">
        <div class="container">
            <div class="mbr-section-head">
                <h4 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                    <strong>Das zeichnet uns aus</strong>
                </h4>

            </div>
            <div class="row mt-4">
                <div class="item features-image col-12 col-md-6 col-lg-4">
                    <div class="item-wrapper">
                        <div class="item-img">
                            <img src="assets/images/ai-chip.png" alt="Künstliche Intelligenz" title="">
                        </div>
                        <div class="item-content">
                            <h5 class="item-title mbr-fonts-style display-5"><strong>KI-Pipelines für Ihr
                                    Unternehmen</strong></h5>

                            <p class="mbr-text mbr-fonts-style mt-3 display-7">Unsere Firma nutzt die fortschrittliche
                                Technologie von
                                ChatGPT und Large Language Models, um maßgeschneiderte KI-Pipelines zu erstellen, die auf
                                die spezifischen
                                Bedürfnisse jeder Organisation zugeschnitten sind. Unser Angebot umfasst die nahtlose
                                Integration Ihrer
                                Daten in eine KI-Pipeline zur Optimierung der Datenverarbeitung und Entscheidungsfindung,
                                weit über bloße
                                Beratung hinaus.<br> <br>Wir implementieren KI-Lösungen und verknüpfen Ihren speziellen
                                Use-Case mit LLMs,
                                wobei wir den Datenschutz stets priorisieren, indem wir komplett private LLMs verwenden und
                                strenge
                                Datenschutzstandards einhalten. Unser Ziel ist es, das Potenzial von KI für Ihr Unternehmen
                                voll
                                auszuschöpfen und effiziente, sichere, und individuell angepasste Lösungen zu bieten.</p>
                        </div>

                    </div>
                </div>
                <div class="item features-image col-12 col-md-6 col-lg-4">
                    <div class="item-wrapper">
                        <div class="item-img">
                            <img src="assets/images/servers.png" alt="Continuous Improvement" title="">
                        </div>
                        <div class="item-content">
                            <h5 class="item-title mbr-fonts-style display-5"><strong>Continuous Improvement</strong></h5>

                            <p class="mbr-text mbr-fonts-style mt-3 display-7">In unserer datengetriebenen Welt ist es
                                essenziell, dass
                                KI-Systeme effizient, sicher und stetig verbessert werden. Daher haben wir ein spezielles
                                Continuous
                                Improvement Konzept für unsere KI-Pipelines entwickelt. Unser Ansatz umfasst einen
                                kundenspezifischen
                                Prozess zur Bewertung und Optimierung der KI-Pipeline, der regelmäßige Bewertungszyklen
                                beinhaltet, um die
                                Leistungsfähigkeit zu analysieren und Verbesserungen vorzunehmen.<br><br>Dies führt dazu,
                                dass
                                implementierte Use-Cases im Laufe der Zeit immer besser werden und relevant bleiben. Dieses
                                Engagement für
                                fortlaufende Optimierung und Exzellenz ist ein zentraler Aspekt unserer Dienstleistung, mit
                                dem Ziel, Sie
                                optimal bei Ihrer digitalen Transformation zu unterstützen.</p>
                        </div>

                    </div>
                </div>
                <div class="item features-image col-12 col-md-6 col-lg-4">
                    <div class="item-wrapper">
                        <div class="item-img">
                            <img src="assets/images/workspace.png" alt="Engineering Excellence" title="">
                        </div>
                        <div class="item-content">
                            <h5 class="item-title mbr-fonts-style display-5"><strong>Engineering Excellence</strong></h5>

                            <p class="mbr-text mbr-fonts-style mt-3 display-7">Neben unseren spezialisierten KI-Lösungen
                                sind wir auch
                                in der Lage, ein breites Spektrum von Dienstleistungen im Bereich der App- und
                                Software-Entwicklung sowie
                                im Requirements-Engineering anzubieten. Unabhängig davon, ob Ihr Projekt einen KI-Use-Case
                                umfasst oder
                                nicht, unser erfahrenes und kompetentes Team verfügt über das nötige Know-how, um
                                maßgeschneiderte
                                Anwendungen und Software zu entwickeln, die genau auf Ihre spezifischen Bedürfnisse und
                                Anforderungen
                                zugeschnitten sind. <br><br>Im Rahmen des Requirements-Engineering analysieren und
                                dokumentieren wir
                                präzise die Anforderungen Ihres Projekts, um sicherzustellen, dass die entwickelte Lösung
                                genau Ihren
                                Vorstellungen entspricht. Unser Ziel ist es stets, Sie mit hochwertigen, effizienten und
                                benutzerfreundlichen Lösungen zu unterstützen, die Ihren Geschäftserfolg vorantreiben.<br>
                            </p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<style scoped>
.cid-tKcoiQvxd4 {
  padding-top: 6rem;
  padding-bottom: 6rem;
  /* background-color: rgb(0, 200, 255); */
  background-color: whitesmoke;
}
.cid-tKcoiQvxd4 img,
.cid-tKcoiQvxd4 .item-img {
  width: 100%;
  height: 100%;
  height: 300px;
  object-fit: cover;
}
.cid-tKcoiQvxd4 .item:focus,
.cid-tKcoiQvxd4 span:focus {
  outline: none;
}
.cid-tKcoiQvxd4 .item {
  cursor: pointer;
  margin-bottom: 2rem;
}
.cid-tKcoiQvxd4 .item-wrapper {
  position: relative;
  border-radius: 12px;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 992px) {
  .cid-tKcoiQvxd4 .item-wrapper .item-content {
    padding: 2rem;
  }
}
@media (max-width: 991px) {
  .cid-tKcoiQvxd4 .item-wrapper .item-content {
    padding: 1rem;
  }
}
.cid-tKcoiQvxd4 .mbr-section-btn {
  margin-top: auto !important;
}
.cid-tKcoiQvxd4 .mbr-section-title {
  color: #232323;
}
.cid-tKcoiQvxd4 .mbr-text,
.cid-tKcoiQvxd4 .mbr-section-btn {
  text-align: left;
}
.cid-tKcoiQvxd4 .item-title {
  text-align: left;
}
.cid-tKcoiQvxd4 .item-subtitle {
  text-align: left;
  color: #bbbbbb;
}
</style>