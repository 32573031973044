// src/plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      "phc_2g6pU7cd4XkhvsMaKG8ekZwhuN7x9LqwEtS6J0OUEjJ",
      {
        api_host: "https://eu.posthog.com",
      }
    );
  },
};