<template>
    <section data-bs-version="5.1" class="features19 cid-tL70rwhezI" id="chatbots">
        <div class="container">
            <div class="row justify-content-center">
                <div class="card col-12 col-lg">
                    <div class="card-wrapper">
                        <h6 class="card-title mbr-fonts-style mb-4 display-2">
                            <strong>KI-Chatbots</strong>
                        </h6>
                        <p class="mbr-text mbr-fonts-style display-7">
                            Entdecken Sie die Zukunft der Geschäftskommunikation: KI-Chatbots.
                            <br>
                            <br>
                            Unser auf künstlicher Intelligenz basierender Chatbot bietet Ihnen eine neue Dimension der Kundeninteraktion.
                            <br>
                            Mit einer tiefgreifenden Fähigkeit zur natürlichen Sprachverarbeitung und kontextbezogenen Antworten revolutioniert er die Art und Weise, wie Sie mit Ihren Kunden kommunizieren.
                            <br>
                            <br>
                            Durch seine Lernfähigkeit und Anpassungsfähigkeit ermöglicht Ihnen unser KI-Chatbot eine effiziente und personalisierte Kundenbetreuung.
                            Bieten Sie Ihren Kunden sofortige Unterstützung - rund um die Uhr, ohne Wartezeiten.
                            <br>
                            <br>
                            Entdecken Sie, wie unsere fortschrittlichen KI-Chatbots Ihnen helfen können, den Kundenservice zu optimieren, die Kundenbindung zu stärken und Ihr Unternehmen in die Zukunft zu führen. Erleben Sie eine neue Ära der Kommunikation!
                        </p>
                        <br>
                        <div class="mbr-section-btn">
                            <router-link to="/chatbots" class="btn btn-primary display-4">Mehr über unsere Chatbots erfahren</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="image-wrapper">
                        <img src="/assets/images/ai-agent.png" alt="AI Agent">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.cid-tL70rwhezI {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #ffffff;
}
.cid-tL70rwhezI .mbr-fallback-image.disabled {
  display: none;
}
.cid-tL70rwhezI .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-tL70rwhezI .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-tL70rwhezI .row {
    flex-direction: column-reverse;
  }
  .cid-tL70rwhezI .image-wrapper {
    margin-bottom: 2rem;
  }
  .cid-tL70rwhezI .card-wrapper {
    padding: 0 1rem;
  }
}
@media (min-width: 992px) {
  .cid-tL70rwhezI .card-wrapper {
    padding-right: 2rem;
  }
}
.cid-tL70rwhezI .image-wrapper img {
  width: 75%;
  object-fit: cover;
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 120px rgb(248, 27, 193);
}
</style>