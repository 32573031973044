import { createApp } from 'vue'
import App from './App.vue'
import * as VueRouter from 'vue-router'

import HomePage from './pages/HomePage.vue'
import NotFound from './pages/NotFound.vue'
import ChatBotPage from './pages/ChatBotPage.vue'
import ChatBotDevPage from './pages/ChatBotDevPage.vue'
import ImprintPage from './pages/ImprintPage.vue'
import DataProtectionPage from './pages/DataProtectionPage.vue'

import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import posthogPlugin from "./plugins/posthog"
import * as Sentry from "@sentry/vue"

const routes = [
    { path: '/', component: HomePage },
    { path: '/imprint', component: ImprintPage },
    { path: '/dataprotection', component: DataProtectionPage },
    { path: '/chatbots', component: ChatBotPage },
    { path: '/chatbot_dev', component: ChatBotDevPage},
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHistory(),
    routes: routes, // short for `routes: routes`
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
        if (savedPosition) {
            return savedPosition
        }
        return { top: 0 }
    },
})

const app = createApp(App).use(Quasar, quasarUserOptions)

Sentry.init({
    app,
    dsn: "https://fbcfffdcfe92b8edaf4d854bd502858a@o4505993021423616.ingest.sentry.io/4505993023062016",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.use(router)
app.use(posthogPlugin)
app.mount('#app')