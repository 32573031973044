<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-9">
                <div class="toccontainer">
                    <ul class="toc">
                        <li><a href="#whatisachatbot">
                            Was ist ein Chatbot?
                        </a></li>
                        <li><a href="#difference">
                            Arten von Chatbots
                        </a></li>
                        <li><a href="#comparison">
                            Vergleich zwischen Scripted Chatbots und KI-Chatbots
                        </a></li>
                        <li><a href="#internalusage">
                            Interner Einsatz von Chatbots
                        </a></li>
                    </ul>
                </div>
                <br />
            </div>

            <div class="title col-12 col-md-9">
                <h3 id="whatisachatbot" class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong>
                        Was ist ein Chatbot?
                    </strong>
                </h3>
                <br>

                <h4 class="mbr-section-subtitle mbr-fonts-style mb-4 display-7">
                    Chatbots sind computergesteuerte Programme, die entwickelt werden, um menschenähnliche Konversationen mit Benutzern zu führen. Sie werden oft in der Kundenkommunikation eingesetzt, um Fragen zu beantworten, Informationen bereitzustellen oder Probleme zu lösen.
                    <br><br>
                    Chatbots können auf verschiedenen Plattformen eingesetzt werden, wie zum Beispiel Websites, Apps, Messaging-Plattformen oder sozialen Medien. Sie können entweder regelbasiert sein oder auf künstlicher Intelligenz (KI) basieren.
                    <br><br>
                    Chatbots können in verschiedenen Branchen eingesetzt werden, wie zum Beispiel im Kundenservice, im E-Commerce, im Gesundheitswesen oder im Bankwesen. Sie können die Effizienz verbessern, die Mitarbeiter- oder die Kundenzufriedenheit steigern und Kosten reduzieren, indem sie repetitive Aufgaben automatisieren und rund um die Uhr verfügbar sind.
                </h4>
                <br><br>
            </div>

            <div class="title col-12 col-md-9">
                <h3 id="difference" class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong>
                        Arten von Chatbots
                    </strong>
                </h3>
                <br>

                <h4 class="mbr-section-subtitle mbr-fonts-style mb-4 display-7">
                    Scripted Chatbots werden durch vordefinierte Regeln und Skripte gesteuert.
                    <br>
                    Sie folgen einem Regelwerk von vorab programmierten Anweisungen, um auf Benutzereingaben zu reagieren.
                    Diese Regeln basieren normalerweise auf festgelegten Mustern und Schlüsselwörtern.
                    <br>
                    Wenn ein Benutzer eine Frage stellt sucht der Chatbot nach Übereinstimmungen mit den vordefinierten Regeln und gibt die hinterlegte Antwort aus.
                    <br>
                    Diese Chatbots haben eine sehr begrenzte Fähigkeit, komplexe Anfragen zu verstehen und sind auf vorprogrammierte Interaktionen beschränkt.

                    <br />
                    <br />
                    KI-Chatbots verwenden künstliche Intelligenz um Benutzeranfragen zu verstehen und darauf zu reagieren.
                    Sie verwenden verschiedene Techniken wie natürliche Sprachverarbeitung und maschinelles Lernen, um den Kontext und die Absicht hinter den Benutzeranfragen zu erfassen.
                    <br>
                    KI-Chatbots können mit sehr großen Datenmengen verknüpft werden, so dass der Chatbot auf eine Vielzahl von (nicht öffentlich verfügbaren) Informationen zugreifen kann.
                    Er kann diese Informationen ausgeben, darauf verlinken oder sie zusammenfassen.

                    <br><br>

                    Welcher Ansatz besser ist, hängt von den spezifischen Anforderungen und Zielen des Chatbots ab.

                    <br><br>

                    Insgesamt bieten KI-Chatbots aufgrund ihrer Fähigkeit, natürliche Sprache besser zu verstehen und kontextbezogene Antworten zu generieren, oft eine überlegene Benutzererfahrung.
                    Bei einfachen und vorhersehbaren Anwendungsfällen können rule-based/Scripted Chatbots immer noch eine effektive Lösung sein.
                </h4>
                <br><br>
            </div>

            <div class="col-12 col-lg-10">
                <h3 id="comparison" class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong>
                        Vergleich zwischen Scripted Chatbots und KI-Chatbots
                    </strong>
                </h3>
                <br />
            </div>

            <div v-for="(card, index) in cards" :key="index" class="col-11 col-sm-5">
                <q-card flat bordered class="my-card">
                    <q-img :id="card.image_id" :src="card.image" />

                    <br />
                    <br />
                    <br />
                    <br />

                    <q-card-section>
                        <div class="text-h4">{{ card.title }}</div>
                    </q-card-section>

                    <br />

                    <q-card-section class="q-pt-none">
                        <h6>Vorteile:</h6>
                        <ul>
                            <li v-for="(advantage, index) in card.advantages" :key="index">{{ advantage }}</li>
                        </ul>
                    </q-card-section>

                    <q-separator inset />
                    <br />

                    <q-card-section>
                        <h6>Nachteile:</h6>
                        <ul>
                            <li v-for="(disadvantage, index) in card.disadvantages" :key="index">{{ disadvantage }}</li>
                        </ul>
                    </q-card-section>
                </q-card>
                <br />
                <br />
                <br />
            </div>

            <div class="title col-12 col-md-9">
                <h3 id="internalusage" class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong>
                        Interner Einsatz von Chatbots
                    </strong>
                </h3>
                <br>

                <h4 class="mbr-section-subtitle mbr-fonts-style mb-4 display-7">
                    Chatbots finden nicht nur in der externen Kundenkommunikation Verwendung, sondern sind auch intern äußerst nützlich. Unternehmen nutzen Chatbots zunehmend, um ihre internen Prozesse zu optimieren und die Produktivität zu steigern. Ein Beispiel für den Einsatz eines Chatbots im internen Bereich ist die Nutzung als Ersatz für eine umständliche Suche in einer Knowledge-Base.
                    <br><br>
                    Stellen wir uns vor, ein Unternehmen hat eine umfangreiche Wissensdatenbank mit wichtigen Informationen und Richtlinien für seine Mitarbeiter. Wenn ein Mitarbeiter eine spezifische Frage hat oder nach bestimmten Informationen sucht, kann die Suche in der Wissensdatenbank mühsam und zeitraubend sein. Hier kommt der interne Chatbot ins Spiel.
                    <br><br>
                    Durch die Integration eines KI-basierten Chatbots können Mitarbeiter einfach eine Frage in natürlicher Sprache stellen und der Chatbot liefert prompt die relevanten Informationen aus der Knowledge-Base. Der Chatbot kann kontextbezogene Antworten bieten, sich an die Bedürfnisse des Mitarbeiters anpassen und sogar auf Follow-up-Fragen reagieren. Dies ermöglicht eine schnelle und effiziente Informationsbeschaffung, ohne dass die Mitarbeiter mehrere Suchanfragen durchführen müssen.
                    <br><br>
                    Die Vorteile eines solchen internen Chatbots sind vielfältig. Erstens sparen die Mitarbeiter wertvolle Zeit und Mühe bei der Suche nach Informationen, was ihre Produktivität und Zufriedenheit steigert. Zweitens reduziert der Chatbot die Arbeitslast des IT- oder Support-Teams, da er als erste Anlaufstelle für Fragen und Probleme dient. Drittens fördert der Chatbot eine selbstständige Arbeitsweise, da die Mitarbeiter jederzeit auf das benötigte Wissen zugreifen können, ohne auf externe Hilfe angewiesen zu sein.
                    <br><br>
                    Insgesamt trägt der Einsatz von Chatbots im internen Bereich dazu bei, die Effizienz des Unternehmens zu steigern, die Mitarbeiterzufriedenheit zu erhöhen und die betrieblichen Kosten zu reduzieren. Durch die Automatisierung von Supportprozessen kann das Unternehmen Ressourcen besser nutzen und eine nahtlose Wissensvermittlung gewährleisten. Die internen Chatbots sind somit eine wertvolle Ergänzung für moderne Unternehmen, die ihren Mitarbeitern ein effektives Arbeitsumfeld bieten möchten.
                </h4>
                <br><br>
            </div>

        </div>
    </div>
</template>

<script setup>
const scripted_advantages = [
    'Begrenzte Fehleranfälligkeit, da alle Antworten vordefiniert sind.',
    'Gut geeignet für einfache und vorhersehbare Interaktionen mit wenig Umfang.',
]
const scripted_disadvantages = [
    'Eingeschränkte Fähigkeit komplexe Anfragen zu verstehen.',
    'Skalierbarkeitsprobleme, da jede Regel manuell erstellt werden muss.',
    'Benötigt umgfangreiche Entwicklung durch sogenannte "Conversation Designer" um diese Regeln zu erstellen.',
    'Schwierigkeiten bei der Bewältigung von unstrukturierten Eingaben oder neuen Anfragen, die nicht in den vordefinierten Regeln enthalten sind.',
    'Können nicht oder nur schwer mit vielen Informationen verknüpft werden.',
]

const ai_advantages = [
    'Besseres Verständnis von natürlicher Sprache und Kontext.',
    'Die Fähigkeit komplexe Anfragen zu verarbeiten und kontextbezogene Antworten zu generieren.',
    'Lernfähigkeit und Verbesserung der Leistung über die Zeit.',
    'Kann mit sehr vielen Informationen verknüpft werden.',
]
const ai_disadvantages = [
    'KI kann unter Umständen zu Halluzinationen neigen.',
    'Benötigt einmaliges, umfangreiches Prompt-Engineering um dem Chatbot beizubringen, wie er auf Benutzeranfragen reagieren soll.',
]

const cards = [
    {
        title: 'Scripted Chatbots',
        image_id: 'scripted-robot',
        image: 'assets/images/scripted-robot.png',
        advantages: scripted_advantages,
        disadvantages: scripted_disadvantages,
    },
    {
        title: 'KI-Chatbots',
        image_id: 'ai-agent',
        image: 'assets/images/ai-agent.png',
        advantages: ai_advantages,
        disadvantages: ai_disadvantages,
    }
]
</script>

<style scoped>
DIV.container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
DIV.toccontainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}

UL.toc {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
    border: 1px solid rgb(210, 210, 210);
    padding: 24px;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.5;
}

.q-card__section>UL {
    text-align: left;
    font-size: 1.2rem;
}

#scripted-robot {
    border-radius: 2px;
    box-shadow: 0 0 120px rgb(45, 248, 27, 1);
}

#ai-agent {
    border-radius: 2px;
    box-shadow: 0 0 120px rgb(0, 89, 255, 1);
}
</style>