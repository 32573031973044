<template>
    <div class="row justify-content-center">
        <div id="chatbotbox" class="col-11 col-lg-8 chatbot-card animate__animated animate__delay-1s animate__fadeInUp"
            :style="hasBorder ? 'border: 1px solid grey; border-radius: 5px;' : ''">

            <!-- <q-btn icon="delete" /> -->

            <div class="chat-output">
                <div class="q-pa-md row justify-center">
                    <div>
                        <q-chat-message :key="key" v-for="(message, key) in messages.slice(-8)" :text="[message.text]"
                            :sent="message.fromChatbot != true"
                            :bg-color="message.fromChatbot == true ? 'light-green-2' : 'light-gray'"
                            :stamp="message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })"
                            class="display-7" text-html />
                        <q-chat-message v-if="isRequestRunning" bg-color="light-green-2" text-html>
                            <q-spinner-dots size="2rem" />
                        </q-chat-message>
                    </div>
                </div>
            </div>
            <br />

            <div>
                <div class="row justify-content-end">
                    <input class="chat-textfield col-12 col-sm col-md col-lg col-xl"
                        v-model="chatTextFieldText" @keyup.enter="sendText()" 
                        :disabled="isRequestRunning" 
                        type="text"
                        id="chatTextField"
                        placeholder="Chatten Sie mit unserem KI-Chatbot">

                    <button id="send-button" @click="sendText()" class="send-button col-4 col-sm-2 col-lg-2 col-xl-1">
                        Senden
                    </button>

                    <button id="random-button" @click="setPromptExample()" class="send-button col-3 col-sm-2 col-md-1 col-lg-1 col-xl-1">
                        <q-icon name="casino" size="24px" />
                    </button>
                </div>
            </div>
            <br /><br />
        </div>
    </div>
</template>
  
<script setup>
import { ref } from 'vue'

defineProps(['hasBorder'])

const sessionId = ref('')
const chatTextFieldText = ref('')
const isRequestRunning = ref(false)
const messages = ref([
    {
        fromChatbot: true,
        text: 'Hallo, ich bin ein KI-Chatbot von Haicu Systems. Ich erzähle Ihnen gerne mehr über unser Unternehmen.<br />Sie können mich alles fragen, was Sie über Haicu Systems wissen möchten.<br />',
        timestamp: new Date(),
    },
])

const promptExamples = [
    "Erzähle mir was etwas über eure Leistungen. Sprich dabei wie ein Pirat.",
    "Schreibe mir ein kurzes Gedicht über eure Leistungen.",
    "Schreibe mir einen kurzen Haiku über eure Leistungen.",
    "Schreibe mir einen übertrieben reißerischen, kurzen Text über eure Leistungen.",
    "Schreibe mir einen kurzen Text über eure Leistungen, der sich anhört, als ob er von einem Kind geschrieben wurde.",
    "Schreibe mir ein kurzes Märchen über eure Leistungen.",
    "Erzähle mir etwas über eure Leistungen. Tu dabei so als ob du sehr unsicher wärst und stammle immer wieder etwas.",
    "Schreibe einen Text für ein Schlager-Lied über eure Leistungen.",
    "Schreibe einen Text für ein Rap-Lied über eure Leistungen.",
]
let currentPromptExampleIndex = calculateRandomPromptExampleIndex()

setPromptExample()

function setPromptExample() {
    let newIndex = currentPromptExampleIndex
    while (newIndex === currentPromptExampleIndex) {
        newIndex = calculateRandomPromptExampleIndex()
    }
    currentPromptExampleIndex = newIndex
    chatTextFieldText.value = promptExamples[newIndex]
}

function calculateRandomPromptExampleIndex() {
    return Math.floor(Math.random() * promptExamples.length)
}

function sendText() {
    if (chatTextFieldText.value.length == 0 || isRequestRunning.value == true) {
        return
    }

    // console.log('Sending text: ' + chatTextFieldText.value)

    //   var url = "https://haicu-company-chatbot.onrender.com/api?model=gpt-4&prompt=" + encodeURIComponent(enteredText)
    var url = "https://haicu-company-chatbot.onrender.com/api?prompt=" + encodeURIComponent(chatTextFieldText.value)
    if (sessionId.value != "") {
        url += "&sessionId=" + encodeURIComponent(sessionId.value)
    }

    // console.log("calling url: " + url)

    messages.value.push({
        fromChatbot: false,
        text: chatTextFieldText.value,
        timestamp: new Date(),
    })

    isRequestRunning.value = true

    // Send the data to the specified address using AJAX
    var xhttp = new XMLHttpRequest()
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4) {
            isRequestRunning.value = false

            // console.log("Got response: " + this.responseText)

            if (this.status === 200) {
                var response = this.responseText
                displayOutput(response)
            } else {
                displayOutput("Error: " + this.status)
            }
        }
    };
    xhttp.open("GET", url, true)
    xhttp.send()
}

function displayOutput(response) {
    var responseObject = JSON.parse(response)
    var responseText = responseObject.response

    // console.log("setting session id to: " + responseObject.session_id)
    sessionId.value = responseObject.session_id

    messages.value.push({
        fromChatbot: true,
        text: responseText.replace(/\n/g, '<br />'),
        timestamp: new Date(),
    })
    chatTextFieldText.value = ''
}
</script>

<style>
.q-message-received {
    margin-right: 32px;
    text-align: left;
}

.q-message-text--received {
    width: intrinsic !important;
    margin-right: auto !important;
}

.q-message-received .q-message-stamp {
    text-align: left;
}

.q-message-sent {
    margin-left: 32px;
    text-align: right;
}

.q-message-text--sent {
    width: intrinsic !important;
    margin-left: auto !important;
}

.q-message-sent .q-message-stamp {
    text-align: right;
}

.q-spinner {
    margin-left: auto;
    margin-right: auto;
}
</style>
<style scoped>
.chat-textfield {
    font-family: 'Open Sans';
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 5px;
}

.send-button {
    font-family: 'Open Sans';
    background-color: #2299aa;
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

#send-button {
    margin-left: 4px;
}

#random-button {
    margin-left: 4px;
}

@media (max-width: 599px) {
    #send-button {
        margin-top: 4px;
    }

    #random-button {
        margin-top: 4px;
    }
}

.chat-output {
    font-family: 'Open Sans';
    margin-top: 10px;
}

.chatbot-card {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.8);
}
</style>