<template>
    <section data-bs-version="5.1" class="footer5 cid-tKcBzWUkzp" once="footers" id="footer5-q">
        <div class="container">
            <div class="media-container-row">
                <div class="col-md-1 col-6">
                    <div class="media-wrap">
                        <router-link to="/">
                            <img src="assets/images/haicu-logo.png" alt="Haicu Systems GmbH">
                        </router-link>
                    </div>
                </div>
                <div class="col-11 col-6">
                    <p class="mbr-text align-right links mbr-fonts-style display-7 router-links">
                        <router-link to="/" class="text-black">
                            Start
                        </router-link>
                        <router-link to="/imprint" class="text-black text-primary">
                            Impressum
                        </router-link>
                        <router-link to="/dataprotection" class="text-black text-primary">
                            Datenschutz
                        </router-link>
                    </p>
                </div>
            </div>
            <!-- <div class="media-container-row">
            <div class="col-md-12"> -->
            <div class="footer-lower">
                <div class="media-container-row">
                    <div class="col-md-12">
                        <hr>
                    </div>
                </div>
                <div class="media-container-row">
                    <div class="col-md-12 copyright">
                        <p class="mbr-text mbr-fonts-style display-7">
                            © Copyright 2023 Haicu Systems GmbH</p>
                    </div>
                </div>
                <!-- </div>
            </div> -->
            </div>

        </div>
    </section>
</template>

<style scoped>
.router-links > A {
    margin-left: 24px;
}

.cid-tKcBzWUkzp {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .media-wrap {
    margin-bottom: 1rem;
    text-align: center;
  }
}
.cid-tKcBzWUkzp .mbr-text {
  color: #767676;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .links {
    text-align: center !important;
  }
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .footer-lower .copyright {
    margin-bottom: 1rem;
    text-align: center;
  }
}
.cid-tKcBzWUkzp .footer-lower hr {
  margin: 1rem 0;
  border-color: #000;
  opacity: 0.05;
}
.cid-tKcBzWUkzp .footer-lower .social-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.cid-tKcBzWUkzp .footer-lower .social-list .mbr-iconfont-social {
  font-size: 1.3rem;
  color: #232323;
}
.cid-tKcBzWUkzp .footer-lower .social-list .soc-item {
  margin: 0 0.5rem;
}
.cid-tKcBzWUkzp .footer-lower .social-list a {
  margin: 0;
  opacity: 0.5;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}
.cid-tKcBzWUkzp .footer-lower .social-list a:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .footer-lower .social-list {
    -webkit-justify-content: center;
    justify-content: center;
  }
}

.cid-tKcBzWUkzp {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .media-wrap {
    margin-bottom: 1rem;
    text-align: center;
  }
}
.cid-tKcBzWUkzp .mbr-text {
  color: #767676;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .links {
    text-align: center !important;
  }
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .footer-lower .copyright {
    margin-bottom: 1rem;
    text-align: center;
  }
}
.cid-tKcBzWUkzp .footer-lower hr {
  margin: 1rem 0;
  border-color: #000;
  opacity: 0.05;
}
.cid-tKcBzWUkzp .footer-lower .social-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.cid-tKcBzWUkzp .footer-lower .social-list .mbr-iconfont-social {
  font-size: 1.3rem;
  color: #232323;
}
.cid-tKcBzWUkzp .footer-lower .social-list .soc-item {
  margin: 0 0.5rem;
}
.cid-tKcBzWUkzp .footer-lower .social-list a {
  margin: 0;
  opacity: 0.5;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}
.cid-tKcBzWUkzp .footer-lower .social-list a:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .footer-lower .social-list {
    -webkit-justify-content: center;
    justify-content: center;
  }
}

.cid-tKcBzWUkzp {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .media-wrap {
    margin-bottom: 1rem;
    text-align: center;
  }
}
.cid-tKcBzWUkzp .mbr-text {
  color: #767676;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .links {
    text-align: center !important;
  }
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .footer-lower .copyright {
    margin-bottom: 1rem;
    text-align: center;
  }
}
.cid-tKcBzWUkzp .footer-lower hr {
  margin: 1rem 0;
  border-color: #000;
  opacity: 0.05;
}
.cid-tKcBzWUkzp .footer-lower .social-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.cid-tKcBzWUkzp .footer-lower .social-list .mbr-iconfont-social {
  font-size: 1.3rem;
  color: #232323;
}
.cid-tKcBzWUkzp .footer-lower .social-list .soc-item {
  margin: 0 0.5rem;
}
.cid-tKcBzWUkzp .footer-lower .social-list a {
  margin: 0;
  opacity: 0.5;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}
.cid-tKcBzWUkzp .footer-lower .social-list a:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .cid-tKcBzWUkzp .footer-lower .social-list {
    -webkit-justify-content: center;
    justify-content: center;
  }
}
</style>