<template>
    <section data-bs-version="5.1" class="menu cid-s48OLK6784" once="menu" id="menu1-h">
        <nav class="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
            <div id="top-navbar-main-menu" class="container">
                <div class="navbar-brand">
                    <span class="navbar-logo">
                        <router-link to="/">
                            <img src="assets/images/haicu-logo.png" alt="Haicu Systems GmbH" style="height: 3.8rem;">
                        </router-link>
                    </span>
                    <span class="navbar-caption-wrap">
                        <router-link to="/" class="text-black text-primary display-7 company-name-header-title">
                            Haicu Systems GmbH
                        </router-link>
                    </span>
                </div>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse"
                    data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
                        <li class="nav-item">
                            <router-link :to="{path: '/', hash: '#services'}" class="nav-link link text-black text-primary display-4">
                                Services
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{path: '/', hash: '#about'}" class="nav-link link text-black text-primary display-4">
                                Unternehmen
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{path: '/', hash: '#contact'}" class="nav-link link text-black text-primary display-4">
                                Kontakt
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{path: '/', hash: '#chatbots'}" class="nav-link link text-black text-primary display-4">
                                KI-Chatbots
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{path: '/', hash: '#usp'}" class="nav-link link text-black text-primary display-4">
                                USP
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{path: '/', hash: '#mission'}" class="nav-link link text-black text-primary display-4">
                                Mission
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </section>
</template>

<style scoped>
@media screen and (max-width: 366px) {
    .company-name-header-title {
        font-size: 0.6em;
    }
}
@media screen and (max-width: 320px) {
    .company-name-header-title {
        font-size: 0.4em;
    }
}
</style>