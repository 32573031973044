<template>
    <section data-bs-version="5.1" class="header1 cid-s48MCQYojq mbr-fullscreen mbr-parallax-background" id="header1-f">
        <div class="mbr-overlay" style="opacity: 0.8; background-color: rgb(255, 255, 255);"></div>

        <div class="align-center container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-8">
                    <h1 class="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>Mehr erreichen mit künstlicher
                            Intelligenz</strong></h1>
                    <h2 class="mbr-section-title mbr-fonts-style mb-3 display-2">Maßgeschneiderte KI-Lösungen für Ihren
                        Erfolg</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.cid-s48MCQYojq {
  background-image: url("~@/assets/images/ai-chip.png");
}
.cid-s48MCQYojq .mbr-section-title {
  text-align: center;
}
.cid-s48MCQYojq .mbr-text,
.cid-s48MCQYojq .mbr-section-btn {
  color: #232323;
  text-align: center;
}
@media (max-width: 991px) {
  .cid-s48MCQYojq .mbr-section-title,
  .cid-s48MCQYojq .mbr-section-btn,
  .cid-s48MCQYojq .mbr-text {
    text-align: center;
  }
}
</style>