<template>
    <section data-bs-version="5.1" class="header13 cid-tKczlv3dQf mbr-fullscreen mbr-parallax-background" id="mission">
        <div class="mbr-overlay" style="opacity: 0.9; background-color: rgb(255, 255, 255);"></div>

        <div class="align-center container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                    <h1 class="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>Unsere Mission</strong></h1>

                    <p class="mbr-text mbr-fonts-style mb-3 display-7"><br>In einer immer komplexer werdenden Geschäftswelt
                        sind die
                        Möglichkeiten, die Künstliche Intelligenz bietet, unendlich. Unser Ziel ist es, Ihr Unternehmen
                        dabei zu
                        unterstützen, sein volles Potenzial mithilfe von KI-Systemen zu entfalten. Unsere maßgeschneiderten
                        KI-Lösungen ermöglichen es, Prozesse zu optimieren, Effizienz zu steigern und neue Erkenntnisse zu
                        gewinnen,
                        die strategische Entscheidungen unterstützen.<br><br>Ein entscheidendes Anliegen ist uns die
                        Entlastung Ihrer
                        Mitarbeiter. Mit der Implementierung unserer KI-Lösungen können repetitive, zeitaufwändige Aufgaben
                        automatisiert werden, was dazu führt, dass sich Ihre Mitarbeiter auf ihre Kernaufgaben und ihr
                        spezielles
                        Fachwissen konzentrieren können. Dies kann zu einer erhöhten Mitarbeiterzufriedenheit, einer
                        Verbesserung der
                        Arbeitsqualität und einer gesteigerten Produktivität führen.<br><br>Wichtig ist uns jedoch zu
                        betonen, dass
                        unser Einsatz von KI nicht dazu gedacht ist, menschliche Arbeitskräfte zu ersetzen. Ganz im
                        Gegenteil: Wir
                        glauben an die Kraft der menschlichen Kreativität und Intuition, die durch KI ergänzt und verstärkt
                        wird. KI
                        ist ein Werkzeug, das dazu dient, menschliche Fähigkeiten zu erweitern, nicht zu ersetzen. So können
                        Ihre
                        Mitarbeiter ihr volles Potenzial ausschöpfen und Ihr Unternehmen auf seinem Weg zum Erfolg noch
                        weiter
                        voranschreiten.</p>


                </div>
            </div>
        </div>
    </section>
</template>