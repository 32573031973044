<template>
    <section data-bs-version="5.1" class="content1 cid-s48udlf8KU">
        <div class="container">
            <div class="row justify-content-center">
                <div class="title col-12 col-md-9">
                    <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2"><strong>Impressum</strong>
                    </h3>
                    <h4 class="mbr-section-subtitle align-center mbr-fonts-style mb-4 display-7">
                        Haicu Systems GmbH
                        <br />
                        IT-Dienstleistungen
                        <br />
                        Posthofstraße 5, 4020 Linz
                        <br />
                        <a href="tel:+43 650 7070809">+43 650 7070809</a>
                        <br />
                        <a href="mailto:office@haicu.at">office@haicu.at</a>
                        <br />
                        <a href="https://www.haicu-systems.com">www.haicu-systems.com</a>
                        <br />
                        <br />
                        UID-Nr: ATU80089627
                        <br />
                        Firmenbuch: FN 614109 b
                        <br />
                        FB-Gericht: Linz
                        <br />
                        Sitz: 4020 Linz
                        <br />
                        Aufsichtsbehörde: Magistrat Linz
                        <br />
                        Berufsrecht: Gewerbeordnung
                        <br />
                        <br />
                        Verbraucher haben die Möglichkeit, Beschwerden an die Online- Streitbeilegungsplattform der EU zu
                        richten: <a target="_blank" href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>. Sie können allfällige Beschwerde auch an die oben angegebene
                        E-Mail-Adresse richten.
                    </h4>
                </div>
            </div>
        </div>
    </section>
</template>