<template>
    <section data-bs-version="5.1" class="features25 cid-tKctOHVgl1 mbr-parallax-background" id="services">
        <div class="mbr-overlay" style="opacity: 0.8; background-color: rgb(255, 255, 255);">
        </div>
        <div class="container">
            <div class="row mb-5">
                <!-- mbr-class="{'container': !fullWidth, 'container-fluid': fullWidth}" -->
                <div class="col-12">
                    <h3 class="mbr-section-title mbr-fonts-style align-center mb-2 display-2">
                        <strong>Unsere Services</strong>
                    </h3>
                    <h5 class="mbr-section-subtitle mbr-fonts-style align-center mb-0 display-7">Wir bieten maßgeschneiderte
                        App-
                        und Software-Entwicklung sowie Requirements-Engineering-Dienstleistungen an.<br>Unser erfahrenes
                        Team
                        entwickelt Lösungen, die Ihren spezifischen Anforderungen entsprechen.&nbsp;<div>Wir unterstützen
                            Sie bei der
                            Erreichung Ihres Geschäftserfolgs mit hochwertigen und benutzerfreundlichen Lösungen.</div>
                    </h5>
                </div>
            </div>
            <div class="row">
                <div class="item features-without-image mb-5 col-12 col-md-3">
                    <div class="item-wrapper align-center">
                        <div class="iconfont-wrapper">
                            <span class="mbr-iconfont mbri-android"></span>
                        </div>
                        <h5 class="card-title mbr-fonts-style display-7"><strong>KI-Pipelines</strong></h5>
                        <p class="card-text mbr-fonts-style mb-0 display-7">Wir implementieren KI-Lösungen (z.B. KI-Chatbots
                            oder
                            KI-Pipelines zur Datenverarbeitung) maßgeschneidert für Ihren speziellen Use-Case.</p>
                    </div>
                </div>
                <div class="item features-without-image mb-5 col-12 col-md-3">
                    <div class="item-wrapper align-center">
                        <div class="iconfont-wrapper">
                            <span class="mbr-iconfont mbri-devices"></span>
                        </div>
                        <h5 class="card-title mbr-fonts-style display-7">
                            <p><strong>App-Entwicklung</strong></p>
                        </h5>
                        <p class="card-text mbr-fonts-style mb-0 display-7">Für eine umfassende Lösung entwickeln wir native
                            Apps
                            individuell für Sie. Dabei greifen wir auf mehr als 13 Jahre Erfahrung in der App-Entwicklung
                            zurück.</p>
                    </div>
                </div>
                <div class="item features-without-image mb-5 col-12 col-md-3">
                    <div class="item-wrapper align-center">
                        <div class="card-box">
                            <div class="iconfont-wrapper">
                                <span class="mbr-iconfont mbri-users"></span>
                            </div>
                            <h5 class="card-title mbr-fonts-style display-7"><strong>Consulting</strong></h5>
                            <p class="card-text mbr-fonts-style mb-0 display-7">Wir beraten Sie bei der Entwicklung Ihrer
                                Softwarelösung
                                und unterstützen Sie bei der Umsetzung Ihrer Ideen.</p>
                        </div>
                    </div>
                </div>
                <div class="item features-without-image mb-5 col-12 col-md-3">
                    <div class="item-wrapper align-center">
                        <div class="iconfont-wrapper">
                            <span class="mbr-iconfont mbri-file"></span>
                        </div>
                        <h5 class="card-title mbr-fonts-style display-7"><strong>Requirements Engineering</strong></h5>
                        <p class="card-text mbr-fonts-style mb-0 display-7">Wir unterstützen Sie in der Anforderungsanalyse
                            und in der
                            Dokumentation für Ihr Projekt</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>